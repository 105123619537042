import React, { Component } from 'react';

class TableHeader extends Component {
    
    raiseSort = path =>{
        const sortColumn = {...this.props.sortColumn};

        if (sortColumn.path === path)
            sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
        else{
            sortColumn.path = path;
            sortColumn.order = 'asc';
        }

        this.props.onSort(sortColumn)
    }

    renderSortIcon = column => {
        const {sortColumn} = this.props;
        
        if (sortColumn.path !== column.path) return null;
            
        return sortColumn.order ==='asc'
                ? <i className="fa-solid fa-sort-asc"></i>
                : <i className="fa-solid fa-sort-desc"></i>;
        
    }

    render() { 
        const { columns } = this.props;

        return (
            <thead>
                <tr>
                    {columns.map(
                            column => 
                            <th key={column.path || column.key}
                                onClick={() => this.raiseSort(column.path)}
                                className="clickable"
                            >
                                {column.label}
                                {this.renderSortIcon(column)}
                            </th>
                        )}
                </tr>
            </thead>
        );
    }
}
 
export default TableHeader;